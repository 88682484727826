/* header
----------------------------------------------------------------------------------------------*/
header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background: transparent;
  .hide {
    visibility: hidden;
    opacity: 0;
  }
  .wrapper {
    width: auto;
    max-width: unset;
    padding: 0 87px 0 80px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .left-header {
      flex: 0 0 203px;
      max-width: 203px;
      figure {
        .logo-black {
          display: none;
        }
      }
    }
    .right-header {
      position: relative;
      display: flex;
      align-items: center;
      nav {
        ul {
          display: flex;
          align-items: center;
          margin: 0;
          li {
            margin-right: 32px;
            margin-bottom: 0;
            padding: 40px 0;
            a {
              font-size: 0.875rem;
              color: $color-initial;
              @media (hover: hover) {
                &:hover {
                  opacity: 0.5;
                }
              }
              &.active {
                font-weight: bold;
                opacity: 1;
              }
            }
            &.have-child-mobile {
              ul {
                display: none;
              }
              .ico {
                display: none;
              }
            }
          }
        }
      }
      .search-float {
        display: none;
      }
      .book {
        margin-right: 64px;
      }
      .input-search-mobile {
        display: none;
      }
      .box-search {
        display: none;
        position: absolute;
        right: 75px;
        top: 34px;
        input {
          background: transparent;
          width: 645px;
          height: 40px;
          font-size: 1rem;
          color: $color-initial;
          padding-left: 40px;
          margin-bottom: 0;
          border-radius: 0;
          border: unset;
          border-bottom: 1px solid $color-initial;
          &::placeholder {
            color: $color-initial;
          }
        }
        button {
          position: absolute;
          left: 0;
          top: 8px;
          width: 21px;
          height: 21px;
          margin: 0;
          padding: 0;
          border: unset;
          opacity: 1;
          color: transparent;
          background: url("/images/material/icon/ic-search.svg") no-repeat;
        }
      }
      .i-search {
        position: absolute;
        top: 40px;
        right: -53px;
        cursor: pointer;
        z-index: 9;
        &:hover {
          opacity: 0.5;
        }
        .close-search {
          display: none;
          position: relative;
          width: 24px;
          height: 20px;
          span {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1.5px;
            background: $color-initial;
            &:nth-child(1) {
              transform: rotate(45deg);
              top: 7px;
            }
            &:nth-child(2) {
              transform: rotate(-45deg);
              top: 7px;
            }
          }
        }
        &.active {
          i {
            display: none;
          }
          .close-search {
            display: block;
          }
        }
      }
      .burger-menu {
        visibility: hidden;
        opacity: 0;
        width: 24px;
        height: 20px;
        right: -57px;
        top: 40px;
        position: absolute;
        display: block;
        cursor: pointer;
        z-index: 99;
        span {
          position: absolute;
          left: 0;
          width: 100%;
          height: 1.5px;
          background: $color-primary;
          transition: all ease-out 0.25s;
          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 10px;
          }
          &:nth-child(3) {
            top: 20px;
          }
        }
        &.active {
          top: 42px;
          span {
            &:nth-child(1) {
              transform: rotate(45deg);
              top: 7px;
            }
            &:nth-child(2) {
              width: 0;
              left: auto;
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
              top: 7px;
            }
          }
        }
      }
    }
    .mobile {
      display: none;
    }
  }
  &:before {
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: url("/images/material/shd-menu.png") no-repeat bottom;
    background-size: cover;
    transition: all 0.3s ease-out;
  }
  aside {
    position: fixed;
    width: 87px;
    height: 100vh;
    top: 0;
    right: 0;
    border-left: 1px solid #d2d2d2;
    transition: all 0.2s ease-out;
  }
  &.float {
    z-index: 7;
    &:before {
      height: 0;
      opacity: 0;
    }
    .wrapper {
      .left-header {
        visibility: hidden;
        opacity: 0;
      }
      .right-header {
        nav {
          visibility: hidden;
          opacity: 0;
          transition: all 0.2s ease-out;
          ul {
            li {
              a {
                color: $color-primary;
              }
            }
          }
        }
        .search-float {
          cursor: pointer;
          i {
            display: block;
            width: 21px;
            height: 21px;
            margin-right: 30px;
          }
          .close-search {
            display: none;
            position: relative;
            width: 16px;
            height: 16px;
            margin-right: 30px;
            span {
              position: absolute;
              left: 0;
              width: 100%;
              height: 1.5px;
              background: $color-primary;
              &:nth-child(1) {
                transform: rotate(45deg);
                top: 7px;
              }
              &:nth-child(2) {
                transform: rotate(-45deg);
                top: 7px;
              }
            }
          }
          &.active {
            i {
              display: none;
            }
            .close-search {
              display: block;
            }
          }
        }
        .book {
          background: $color-primary;
          border-color: $color-primary;
          color: $color-initial;
          font-weight: 500;
        }
        .box-search {
          right: 220px;
          input {
            width: 545px;
            color: $color-primary;
            border-bottom: 1px solid $color-primary;
            &::placeholder {
              color: $color-primary;
            }
          }
          button {
            background: url("/images/material/icon/ic-search-black.svg")
              no-repeat;
          }
        }
        .i-search {
          visibility: hidden;
          opacity: 0;
        }
        .burger-menu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &.white {
      .wrapper {
        .right-header {
          nav {
            ul {
              li {
                a {
                  color: $color-initial;
                }
              }
            }
          }
          .search-float {
            i {
              svg {
                path {
                  stroke: $color-initial;
                }
              }
            }
            .close-search {
              span {
                background: $color-initial;
              }
            }
          }
          .box-search {
            input {
              color: $color-initial;
              border-bottom: 1px solid $color-initial;
              &::placeholder {
                color: $color-initial;
              }
            }
            button {
              background: url("/images/material/icon/ic-search.svg") no-repeat;
            }
          }
          .book {
            background: transparent;
            border-color: $color-initial;
            color: $color-initial;
          }
          .burger-menu {
            span {
              background: $color-initial;
            }
          }
        }
      }
      &.active {
        .wrapper {
          background: $color-primary;
          border-bottom: unset;
          .left-header {
            figure {
              .logo-white {
                display: block;
              }
              .logo-black {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.active {
    z-index: 10;
    .wrapper {
      background: $color-initial;
      border-bottom: 1px solid #d2d2d2;
      // position: relative;
      // z-index: 1;
      .left-header {
        visibility: visible;
        opacity: 1;
        figure {
          .logo-white {
            display: none;
          }
          .logo-black {
            display: block;
          }
        }
      }
      .right-header {
        nav {
          visibility: visible;
          opacity: 1;
          &.hide {
            visibility: hidden;
            opacity: 0;
          }
        }
        .search-float {
          display: block;
        }
        .book {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
    }
  }
  &.no-shd {
    &::before {
      display: none;
    }
    .wrapper {
      .i-search {
        svg {
          path {
            stroke: $color-primary;
          }
        }
      }
    }
  }
  &.black-color {
    .wrapper {
      .left-header {
        figure {
          .logo-black {
            display: block;
          }
          .logo-white {
            display: none;
          }
        }
      }
      .right-header {
        nav {
          ul {
            li {
              a {
                color: $color-primary;
              }
            }
          }
        }
        .book {
          border: 1px solid $color-primary;
          color: $color-primary;
        }
        .i-search {
          .close-search {
            span {
              background: $color-primary;
            }
          }
        }
        .box-search {
          input {
            color: $color-primary;
            border-bottom: 1px solid $color-primary;
            &::placeholder {
              color: $color-primary;
            }
          }
          button {
            background: url("/images/material/icon/ic-search-black.svg")
              no-repeat;
          }
        }
      }
    }
    &.float {
      .wrapper {
        .right-header {
          .book {
            color: $color-initial;
          }
        }
      }
    }
  }
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
  padding: 64px 87px 198px 0;
  background: var(--c-primary-beige);
  text-align: center;
  position: relative;
  z-index: 9999;
  &::after {
    position: absolute;
    width: 100%;
    height: 69px;
    content: "";
    left: 0;
    bottom: 56px;
    z-index: -1;
    background: url("/images/material/bg-footer.svg") repeat-x 0 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 24px 0;
    li {
      padding: 10px 20px;
      a {
        font-size: 0.875rem;
        color: $color-primary;
      }
    }
  }
  .socmed {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    a {
      padding: 0 8px;
      display: block;
      img {
        display: block;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  p {
    font-size: 0.75rem;
    margin-bottom: 0px;
    color: #929292;
    a {
      color: #929292;
    }
  }
  .logo-footer {
    opacity: 0.19;
  }
  .vector {
    position: fixed;
    bottom: 32px;
    right: 15px;
    line-height: 0;
    &__black {
      display: none;
    }
    svg {
      path {
        transition: all 0.2s ease-out;
      }
    }
    &.black {
      .vector__white {
        display: none;
      }
      .vector__black {
        display: block;
      }
      svg {
        path {
          fill: $color-primary;
        }
      }
    }
  }
  &.only-logo {
    padding: 0;
    background-color: transparent;
    &::after {
      display: none;
    }
  }
  .branch {
    span {
      display: block;
      font-family: "Gotham", sans-serif;
      font-size: 1rem;
      text-transform: uppercase;
      color: #000000;
    }
    ul {
      margin-top: 16px;
    }
  }
}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1400px) {
  header .wrapper .right-header nav ul li{
    margin-right: 24px;
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  header {
    .wrapper {
      padding: 0 65px 0 56px;
      .left-header {
        flex: 0 0 138px;
        max-width: 138px;
      }
      .right-header {
        .book {
          margin-right: 10px;
        }
        .i-search {
          right: -47px;
          top: 41px;
        }
        .burger-menu {
          right: -47px;
          top: 42px;
        }
        nav {
          ul {
            li {
              margin-right: 10px;
              a {
                font-size: 0.75rem;
              }
            }
          }
        }
        .box-search {
          top: 30px;
          input {
            width: 445px;
          }
        }
      }
    }
    &.white {
      .wrapper {
        .right-header {
          .box-search {
            input {
              padding-right: 80px;
            }
          }
        }
      }
    }
    &.float {
      .wrapper {
        .right-header {
          .box-search {
            input {
              width: 500px;
            }
          }
          nav {
            ul {
              li {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    aside {
      width: 65px;
    }
  }
  footer {
    padding: 80px 72px 168px 0;
    &::after {
      height: 50px;
      background-size: auto 100%;
      bottom: 38px;
    }
    ul {
      max-width: 760px;
      margin: 24px auto;
    }
    .vector {
      bottom: 28px;
      right: 4px;
      width: 52px;
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  header {
    .wrapper {
      padding: 0 72px 0 68px;
      .left-header {
        figure {
          padding: 32px 0;
          img {
            width: 120px;
          }
        }
      }
      .right-header {
        display: block;
        top: 0;
        right: -100%;
        position: fixed;
        width: 100%;
        height: 100%;
        background: $color-initial;
        transition: all ease-out 0.25s;
        nav {
          display: block;
          padding: 110px 96px 10px 75px;
          ul {
            display: block;
            li {
              margin: 0;
              padding: 10px 0;
              a {
                color: $color-primary;
              }
              &.have-child-mobile {
                position: relative;
                > a {
                  display: block;
                  position: relative;
                }
                ul {
                  padding-left: 20px;
                  li {
                    padding: 5px 0px;
                    a {
                      text-transform: uppercase;
                      opacity: 0.5;
                    }
                  }
                }
                .ico {
                  display: block;
                  position: absolute;
                  right: -6px;
                  top: 7px;
                  width: 30px;
                  height: 30px;
                  &:before,
                  &:after {
                    position: absolute;
                    width: 14px;
                    height: 2px;
                    right: 8px;
                    top: 50%;
                    content: "";
                    background-color: $color-primary;
                    transition: all 0.2s ease-out;
                  }
                  &:after {
                    transform: rotate(90deg);
                  }
                }
                &.active {
                  .ico {
                    &:after {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }
        }
        .book {
          display: none;
        }
        .burger-menu {
          right: -48px;
          &.active {
            top: 10px;
            span {
              background: $color-primary;
            }
          }
        }
        .input-search-mobile {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: 0;
          visibility: hidden;
          background-color: #fff;
          padding: 0 140px 40px 75px;
          input {
            background: transparent
              url("/images/material/icon/ic-search-black.svg") no-repeat 5px;
            padding-left: 35px;
          }
        }
      }
      .mobile {
        display: flex;
        align-items: center;
        position: fixed;
        right: 0;
        padding-right: 24px;
        z-index: 99;
        .button {
          margin-right: 48px;
        }
        .burger-menu {
          width: 24px;
          height: 20px;
          position: relative;
          display: block;
          cursor: pointer;
          z-index: 99;
          span {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1.5px;
            background: $color-initial;
            transition: all ease-out 0.25s;
            &:nth-child(1) {
              top: 0;
            }
            &:nth-child(2) {
              top: 10px;
            }
            &:nth-child(3) {
              top: 20px;
            }
          }
          &.active {
            span {
              &:nth-child(1) {
                transform: rotate(45deg);
                top: 7px;
              }
              &:nth-child(2) {
                width: 0;
                left: auto;
              }
              &:nth-child(3) {
                transform: rotate(-45deg);
                top: 7px;
              }
            }
          }
        }
      }
    }
    &.float {
      .wrapper {
        .mobile {
          .button {
            background: $color-primary;
            border-color: $color-primary;
            color: $color-initial;
          }
          .burger-menu {
            span {
              background: $color-primary;
            }
          }
        }
      }
      &.white {
        .wrapper {
          .left-header {
            figure {
              .logo-white {
                display: none;
              }
              .logo-black {
                display: block;
              }
            }
          }
          .right-header {
            nav {
              ul {
                li {
                  a {
                    color: $color-primary;
                  }
                }
              }
            }
          }
        }
        &.active {
          .wrapper {
            .left-header {
              figure {
                .logo-white {
                  display: none;
                }
                .logo-black {
                  display: block;
                }
              }
            }
            .right-header {
              nav {
                ul {
                  li {
                    a {
                      color: $color-primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.white {
      .wrapper {
        .mobile {
          .button {
            background: unset;
            border-color: $color-initial;
          }
          .burger-menu {
            span {
              background: $color-initial;
            }
          }
        }
      }
    }
    &.active {
      z-index: 99999;
      .wrapper {
        background: unset;
        border: unset;
        .left-header {
          z-index: 9;
        }
        .right-header {
          right: 0;
          .search-float {
            display: none;
          }
          .input-search-mobile {
            opacity: 1;
            visibility: visible;
          }
        }
        .mobile {
          .button {
            background: unset;
            border-color: $color-primary;
            color: $color-primary;
          }
          .burger-menu {
            span {
              background: $color-primary;
            }
          }
        }
      }
    }
    &.no-shd {
      .wrapper {
        .mobile {
          .burger-menu {
            span {
              background: var(--c-primary-black);
            }
          }
        }
      }
    }
  }
  footer {
    .wrapper {
      margin: 0 68px;
    }
    ul {
      li {
        padding: 10px 16px;
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  header {
    &:before {
      height: 87px;
    }
    .wrapper {
      padding: 0 72px 0 25px;
      .left-header {
        figure {
          padding: 22px 0;
          img {
          }
        }
      }
      .right-header {
        nav {
          padding: 110px 27px 50px;
          box-sizing: border-box;
          ul {
            li {
              a {
                font-size: 1rem;
              }

              &.have-child-mobile {
                .ico {
                  top: 8px;
                }
              }
            }
          }
        }
        .input-search-mobile {
          left: 0px;
          right: 0;
          bottom: 0px;
          padding: 0 27px 20px;
        }
      }
      .mobile {
        top: 20px;
        .button {
          margin-right: 24px;
        }
      }
    }
    aside {
      display: none;
    }
    &.float {
      left: auto;
      right: 0;
      top: 0;
      width: 72px;
      z-index: 999;
      height: 72px;
      &.white {
        .wrapper {
          .left-header {
            figure {
              .logo-white {
                display: none;
              }
              .logo-black {
                display: block;
              }
            }
          }
        }
        &.active {
          .wrapper {
            .left-header {
              figure {
                .logo-white {
                  display: none;
                }
                .logo-black {
                  display: block;
                }
              }
            }
            .right-header {
              nav {
                ul {
                  li {
                    a {
                      color: $color-primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .wrapper {
        .mobile {
          top: 30px;
          .button {
            display: none;
          }
        }
        .right-header {
          nav {
            ul {
              li {
                margin-right: 0;
              }
            }
          }
        }
      }
      &.active {
        width: auto;
        left: 0;
        height: auto;
        .wrapper {
          .mobile {
            top: 20px;
            .button {
              display: block;
            }
          }
        }
      }
    }
    &.active {
      .wrapper {
        background: unset;
        border: unset;
        .left-header {
          z-index: 9;
        }
        .right-header {
          right: 0;
          .search-float {
            display: none;
          }
        }
      }
    }
    &.no-shd {
      .wrapper {
        .mobile {
          .burger-menu {
            span {
              background: var(--c-neutral-white);
            }
          }
        }
      }
      &.black-color {
        .wrapper {
          .mobile {
            .burger-menu {
              span {
                background: var(--c-primary-black);
              }
            }
          }
        }
      }
    }
  }
  footer {
    padding: 60px 0 139px;
    z-index: 9;
    &::after {
      background-size: contain;
      background-position: left -140px center;
      bottom: 21px;
      height: 49px;
    }
    .wrapper {
      margin: 0 24px;
    }
    ul {
      flex-wrap: wrap;
      li {
        padding: 8px;
        a {
          font-size: 0.875rem;
        }
      }
    }
    p {
      font-size: 0.688rem;
    }
    .copyright {
      max-width: 295px;
      margin: 0 auto;
    }
    .vector {
      display: none;
    }
  }
}
