/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/

/* general
----------------------------------------------------------------------------------------------*/
@import "../milligram/Color";    // import your general color from milligram
@import "../milligram/Variables";
/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../pages/general";


/* home styling
----------------------------------------------------------------------------------------------*/
.home {
  h1 {
    font-size: 4rem; 
  }
  h2 {
    font-size: 3rem;
  }
  h2,h1 {
    line-height: 1.1;
    font-family: 'Canela';
    color: var(--c-primary-blue);     
  }
  .wrapper {
    margin: 0 87px 0 84px;
  }
  &.banner {
    overflow: hidden;
    .banner-home {
      width: 100%;
      height: 100vh;
      video {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
      figure {
        width: 100%;
        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
  &.about {
    position: relative;
    overflow: hidden;
    .wrapper {
      width: auto;
      max-width: unset;
      margin: 0 248px 0 160px; 
      padding: 110px 0 0;
    }
    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto; 
      width: 160px; 
      background: repeating-linear-gradient(to right, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 32px, transparent 32px, transparent 64px);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .wrap-about {
      z-index: 3; 
      position: relative;
      &__top {
        display: flex;
        h2,h1 {          
          width: 600px; 
          margin-bottom: 48px; 
          position: relative;
          left: -150px;
        }
        &__left {
          width: 455px;
          padding-top: 113px;
        }
        &__right {
          width: calc(100% - 455px);
          padding-left: 73px; 
          position: relative;
          z-index: 3; 
        }
        &--text {
          flex: 0 0 100%;
          max-width: 100%; 
          display: flex;
          margin-bottom: 48px; 
          p {
            width: 50%;
            margin-bottom: 0;
            &:nth-child(1) {
              padding-right: 12px;
            }
            &:nth-child(2) {
              padding-left: 12px; 
            }
          }
        }
      }
      &__bottom {
        display: flex;
        justify-content: space-between;        
        &--right {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 455px;
          box-sizing: border-box;
          figure {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.living {
    padding: 80px 0 26px;   
    position: relative;
    background: var(--c-primary-beige); 
    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 87px);
      height: 288px;
      left: 0;
      bottom: 194px;  
      background: repeating-linear-gradient(to bottom, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 32px, transparent 32px, transparent 64px);
    }
    &:after {
      content: '';
      width: 100%; 
      height: 194px;
      position: absolute;
      bottom: 0;
      left: 0;
      background:var(--c-neutral-white); 
    }
    .wrapper {
      z-index: 3; 
    }
    .wrap-living {
      padding-right: 84px;
      &__text {
        max-width: 770px;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 55px;
        h2 {
          margin-bottom: 24px;
        }
      }
      &__image {
        .slider-living {
          &__list {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              bottom: 16px;
              left: 0;
              width: 100%;
              height: 20px;
              z-index: 3; 
              background: repeating-linear-gradient(to bottom, var(--c-neutral-white, #FFFFFF), var(--c-neutral-white, #FFFFFF) 3px, transparent 3px, transparent 8px);
              opacity: .4;
            }
            figure {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: var(--c-primary-black); 
                opacity: .1; 
              }
              img {
                width: 100%;
              }
            }
            figcaption {
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 0;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 48.66%, rgba(0, 0, 0, 0.80) 100%);
            }
            &--title {
              padding-left: 72px;
              h2 {
                color: $color-initial;
                margin: 0;
                line-height: normal;
                font-size: 2.5rem; 
              }
            }
            &--text {
              max-width: 460px;
              padding: 24px 0 100px 72px;
              color: $color-initial;
              p {
                margin-bottom: 24px;
                font-weight: 700; 
                font-size: .813rem; 
              }
              a {
                color: $color-initial;
              }
            }
          }
        }
        .wrap-asnav {
          display: flex;
          justify-content: center;
        }
        .asnav-living {
          width: 1150px;
          padding: 38px 0 0;
          margin: 0;
          .slick-slide > div {
            margin: 0 30px;
          }
          li {
            font-size: .938rem;
            font-weight: 500;
            text-transform: uppercase;
            text-align: center;
            // padding: 0 40px 15px;
            padding: 0 0 15px;
            margin: 0;
            color: $color-primary;
            opacity: 0.4;
            cursor: pointer;
            position: relative;
            transition: all ease-out 0.25s;
            &:hover {
              opacity: 0.7;
            }
            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 2px;
              bottom: 9px;
              left: 50%;
              transform: translateX(-50%); 
              background: var(--c-primary-yellow); 
              transition: all .3s ease-out; 
            }
          }
          .slick-current {
            li {
              opacity: 1;
              &::before {
                width: 32px;
              }
            }
          }
        }
      }
    }
  }
  &.health {
    padding: 140px 0 223px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 438px;
      height: 496px;
      top: 40px;
      right: 0; 
      background: url('/images/material/bg-activities-logo.svg') no-repeat 0 0;
    }
    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 87px);
      height: 73px;
      left: 0;
      bottom: 0;
      background: url('/images/material/bg-activities-yellow.svg') repeat-x 0 0;  
    }
    .wrapper {
      z-index: 3; 
    }
    .wrap-health {
      padding: 0 160px 0 76px;
      &__text {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; 
        margin-bottom: 64px;
        h2 {
          flex: 0 0 400px;
          max-width: 400px;
          margin-bottom: 0;
        }
        p {
          flex: 0 0 calc(100% - 440px);
          max-width: calc(100% - 440px) ;
          margin-bottom: 0;
        }
      }
      .row {
        .column {
          margin-bottom: 20px;
        }
      }
      .health-thumb {
        figure {
          margin-bottom: 24px;
          img {
            width: 100%;
          }
        }
        figcaption {
          h4 {
            margin-bottom: 0;
            font-family: inherit; 
            font-size: .938rem; 
            font-weight: 500; 
          }
        }
      }
    }
  }
  &.dining-cocana {
    position: relative;
    padding: 360px 0;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .wrap-dining {
      padding: 0 160px 0 76px; 
      color: $color-initial;
      display: flex;
      &__left {
        width: 416px;
      }
      &__right {
        width: calc(100% - 416px); 
        padding-left: 195px; 
      }
      h2, a {
        color: $color-initial;
      }
    }
  }
  .scroll-down {
    display: none;
  }
}

@media (min-width: 1480px) {
  .home {
    .wrapper{
      margin: 0 auto;
    }
    &.living{
      .wrapper {
        max-width: 100%;
        margin: 0 87px 0 84px;
        width: auto;
      }
    }
  }
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1280px) {
  .home.living .wrap-living__image .asnav-living {
    width: 100%;
    .slick-slide > div {
      margin: 0 15px;
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .home {
    h1 {
      font-size: 3.75rem; 
    }
    h2 {
      font-size: 2.5rem; 
    }
    .wrapper {
      margin: 0 72px 0 56px;
    }
    &.banner{
      .banner-home{
        &.banner-auto-height{
          height: auto;
          video{
            display: block;
            position: relative;
            top: auto;
            height: auto;
          }
        }
      }
    }
    &.about {
      padding: 0;      
      .wrapper {
        padding: 189px 0 0;
        margin: 0 72px 0 56px;
      }
      .wrap-about {
        margin-left: 0;
        &__top {
          h2,h1 {
            width: 520px; 
            left: -115px;
            margin-right: 40px;
            margin-bottom: 32px; 
          }
          &__left {
            width: 340px; 
            padding-top: 110px;
          }
          &__right {
            width: calc(100% - 340px); 
            padding-left: 42px; 
            padding-right: 70px; 
          }
          &--text {
            margin-bottom: 32px;
            p {
              max-width: unset;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        &__bottom {          
          &--right {
            padding-left: 340px;
            figure {
              img {
                height: 166px;
              }
            }
          }
        }
      }
    }
    &.living {
      padding: 80px 0 0;
      &::before {
        width: calc(100% - 72px);
        bottom: 172px;
      }
      &::after {
        height: 172px; 
      }
      .wrap-living {
        padding-right: 56px;
        &__text {
          padding-bottom: 16px; 
          max-width: 680px; 
        }
        &__image{
          .slider-living{            
            &__list{
              &::before {
                bottom: 20px;
              }
              figcaption{                
                padding: 0 0 72px 72px; 
              }
              &--title{
                padding-left: 0;
                border: none;
                h2{
                  font-size: 2rem;
                  margin-bottom: 16px;
                }
              }
              &--text{
                padding: 0;
                max-width: 400px; 
              }
            }
          }
          .wrap-asnav {
            margin: 0 -8px;
          }
          .asnav-living {
            padding: 24px 0 0; 
            .slick-slide > div {
              margin: 0 8px;
            }
            li {
              font-size: .813rem;
            }
          }
        }
      }
      
    }
    &.health {
      padding: 80px 0 150px;
      &::before {
        width: 272px;
        height: 376px;
        background-size: auto 100%; 
      }
      &::after {
        height: 55px;
        width: calc(100% - 72px); 
        background-size: auto 100%; 
      }
      .wrap-health {
        margin-right: 56px;
        padding: 0;
        &__text {
          margin-bottom: 40px;
          h2 {
            flex: 0 0 298px;
            max-width: 298px;
            padding-right: 20px;
          }
          p {
            flex: 0 0 calc(100% - 298px);
            max-width: calc(100% - 298px);
          }
        }
        .row {
          .column {
            margin-bottom: 32px;
            &.column-25 {
              flex: 0 0 50%;
              max-width: 50%;
            }
          }
        }
      }
    }
    &.dining-cocana {
      padding: 280px 0;
      .wrap-dining {
        padding: 0; 
        &__left {
          width: 340px; 
        }
        &__right {
          width: calc(100% - 340px);
          padding-left: 90px; 
        }
      }
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .home {
    h1 {
      font-size: 3rem; 
    }
    .wrapper {
      margin: 0 72px 0 68px;
    }
    &.about {      
      .bg {
        width: 84px;
        background: repeating-linear-gradient(to right, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 20px, transparent 16px, transparent 32px);
      }
      .wrapper {
        margin: 0 72px 0 68px;
        padding: 180px 0 100px; 
      }
      .wrap-about {
        margin-right: 68px;
        &__top {
          flex-direction: column;
          margin-bottom: 30px;         
          h2,h1 {
            flex: unset;
            max-width: unset;
            width: auto;
            left: 0; 
          }
          &__left {
            width: 100%;
            padding-top: 0; 
            order: 2;
            figure {
              height: 240px;
              overflow: hidden;
              display: flex;
              align-items: center;              
            }
          }
          &__right {
            width: 100%;
            padding: 0 0 0 70px;
            order: 1; 
            margin: 0 0 50px;
          }
          &--text {
            flex: unset;
            max-width: unset;
            margin: 0 0 24px 50px;
            flex-direction: column;
            p {
              width: 100%; 
              max-width: 100%; 
              padding: 0;
              margin: 0 0 24px;
              &:nth-child(1) {
                padding: 0;
              }
              &:nth-child(2) {
                padding: 0;
              }
            }
          }
          .readmore {
            margin: 0 0 0 50px;
          }
        }
        &__bottom {
          padding-top: 0;
          flex-direction: column;
          &--left {
            flex: unset;
            max-width: unset;
            margin-bottom: 24px;
            figure {
              img {
                width: 100%;
                height: 240px;
              }
            }
          }
          &--right {
            flex: unset;
            max-width: unset;
            padding: 0;
            figure {
              img {
                width: 100%;
                height: 240px;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    &.living {
      &::before {
        height: 148px;
        background: repeating-linear-gradient(to bottom, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 20px, transparent 16px, transparent 32px);
      }
      .wrap-living {
        padding-right: 68px;
        &__image {
          .slider-living {
            &__list {
              figure {
                img {
                  height: 665px;
                  object-fit: cover;
                }
              }
              figcaption{
                top: auto;
                height: auto;
                right: 0;
                width: auto;
                padding: 0 24px 73px;
                justify-content: flex-start;
              }
              &--text {
                max-width: 480px; 
              }
            }
          }
          
          .asnav-living {
            // width: 496px;
            padding: 20px 0 0;
            .slick-slide > div {
              margin: 0 12px;
            }
            .slick-slide {              
              li {
                font-size: .75rem;
                max-width: 120px;
              }
            }
          }
        }
      }
    }
    &.health {
      padding: 78px 0;
      &::after {
        width: 100%;
      }
      &::before {
        display: none; 
      }
      .wrap-health {
        margin-right: 68px;
        &__text {
          display: block;
          margin-bottom: 33px;
          h2 {
            max-width: unset;
            margin-bottom: 23px;
            padding-right: 0;
          }
          p {
            max-width: unset;
          }
        }
        .health-thumb {
          figure {
            margin-bottom: 20px; 
            height: 160px;
            display: flex;
            align-items: center;
            overflow: hidden;
          }
          figcaption {
            h4 {
              font-size: 1rem; 
            }
          }
        }
        .row {
          .column {
            margin-bottom: 24px;
            &.column-33 {
              flex: 0 0 100%; 
              max-width: 100%; 
            }
          }
        }
      }
    }
    &.dining-cocana {
      .wrap-dining {
        padding: 0;
        flex-direction: column;
        &__right {
          width: 100%;
          padding: 0 60px 0 0; 
        }
      }
    }
    .scroll-down {
      display: block;
    }
  }
}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  header{
    &.float{
      .wrapper{
        .mobile{
          top: 20px;
          button{
            display: block;
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
  .home {
    h1 {
      font-size: 2.25rem;
    }
    h2 {
      font-size: 2rem;  
    }
    .wrapper {
      margin: 0 24px;
    }
    &.about {
      .bg {
        width: 72px;
        top: 50px;
        text-align: center;  
        background: repeating-linear-gradient(to right, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 16px, transparent 12px, transparent 24px);      
      }
      .wrapper {
        margin: 0 24px;
        padding: 50px 0 77px;
      }
      .wrap-about {
        margin-right: 0;
        &__top {
          margin-bottom: 24px;
          h1, h2 {
            margin-right: 0; 
            margin-bottom: 16px; 
          }
          &__left {
            figure {
              height: 180px; 
            }
          }
          &__right {
            padding: 0;
            margin: 0 0 0;
            padding: 0 0 30px;
            background: var(--c-neutral-white); 
          }
          h2 {
            margin-right: 0;
            margin-bottom: 24px;
          }
          &--text {
            margin: 0 0 24px; 
            p {
              margin-right: 0;
            }
          }
          .readmore {
            margin: 0; 
          }
        }
        &__bottom {
          &--right {
            figure {
              img {
                height: 180px; 
              }
            }
          }
        }
      }
    }
    &.living {
      padding: 40px 0;
      &::before {
        height: 128px;
        width: 100%;
        background:repeating-linear-gradient(to bottom, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 16px, transparent 12px, transparent 28px);
      }
      .wrap-living {
        padding-right: 0;
        &__image {
          .slider-living {
            .slick-arrow{
              position: absolute;
              bottom: 40%;
              font-size: 0;
              width: 44px;
              height: 44px;
              line-height: 1;
              opacity: .6;
              z-index: 3;
              right: 0;
              background:#000 url('/images/material/icon/ic-arrow-right-white.svg')no-repeat center;
              display: none !important; 
              &.slick-prev{
                right: auto;
                left: 0;
                transform: rotate(-180deg);
              }
              &:hover{
                opacity: 1;
              }              
            }
            &__list {
              &::before {
                bottom: 12px; 
                background: repeating-linear-gradient(to bottom, var(--c-neutral-white, #FFFFFF), var(--c-neutral-white, #FFFFFF) 2px, transparent 2px, transparent 9px); 
              }
              figure {
                img {
                  height: 663px;
                  object-fit: cover;
                }
              }
              &--title {
                h2 {
                  font-size: 1.5rem;
                }
              }
              figcaption{
                padding:24px 24px 77px 24px; 
              }
            }
          }
          .wrap-asnav {
            margin: 0;
          }
          .asnav-living {
            width: calc(100% + 48px);
            .slick-slide {
              li {
                padding: 0 0 20px;
              }
            }
          }
        }
        &__text {
          padding-bottom: 10px;
        }
      }
      &::after {
        width: 100%;
      }
    }
    &.health {
      padding: 25px 0 100px;
      .wrap-health {
        margin-right: 0;
        .row {
          .column {
            &.column-25 {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
        .health-thumb {
          figcaption {
            h4 {
              font-size: .938rem; 
            }
          }
        }
      }
    }
    &.dining-cocana {
      padding: 260px 0; 
      .wrap-dining {
        margin-right: 0;
        &__left {
          width: 280px;
        }
        &__right {
          padding: 0; 
        }
      }
    }
  }
}