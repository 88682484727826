@import "../milligram/Color";

/* general styling
----------------------------------------------------------------------------------------------*/
.std-content {
  margin-top: 120px;
  padding-bottom: 50px;
}

.banner-top {
  height: 100svh;
  overflow: hidden;
  figure {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100svh;
      object-fit: cover;
    }
    &::before {
      content: '';
      width: 100%;
      height: 226px;
      position: absolute;
      top: 0;
      left: 0;
      // opacity: 0.7;
      opacity: 0.4;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    }
    &:after {
      content: '';
      width: 100%;
      height: 428px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.7;
      transform: matrix(1, 0, 0, -1, 0, 0);
      display: none;
    }
  }
  figcaption {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 140px;
    h2 {
      max-width: 637px;
      font-size: 6rem;
      margin-bottom: 40px;
      color: $color-initial;
    }
  }
}
.scroll-down {
  position: absolute;
  left: 0;
  bottom: 70px;
  transform: rotate(-90deg);
  a {
    color: $color-initial;
    font-size: .688rem;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      width: 72px;
      height: 1px;
      background: $color-initial;
    }
  }
}
.navbar-page {
  background: $color-initial;
  // padding: 31px 80px;
  padding: 31px 65px 31px 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D2D2D2;
  position: sticky;
  top: 0;
  z-index: 9;
  &__left {
    h3 {
      margin-bottom: 0;
      font-size: 2rem;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    a {
      font-weight: 700;
      margin-right: 64px;
      &:last-child{
        margin-right: 0;
      }
      &.button {
        font-weight: 400;
      }
    }
    nav {
      ul {
        display: flex;
        margin-bottom: 0;
        li {
          a{
            opacity: 0.5;
            margin-right: 64px;
            &:last-child {
              margin-right: 64px;
            }
            &.active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  // .book{
  //   opacity: 0;
  //   visibility: hidden;
  // }
}
.enjoy {
  padding: 138px 0;
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .wrap-enjoy {
    margin-right: 87px;
    text-align: center;
    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin:0 auto 24px;
      flex-direction: column;
      width: 530px;
      h2 {
        margin: 0 auto 24px;
        max-width: 350px;
        line-height: 1.1;
        font-family: "Canela";
        color: var(--c-primary-blue);
        font-size: 3rem;
        text-transform: inherit;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}

.content-middle{
  width: calc(100% - 87px);
  min-height: 400px;
  .enjoy {
    .bg {
      width: calc(100% + 87px);
    }
    .wrap-enjoy {
      margin-right: 0;
    }
  }
  &.on-stiky{
    .navbar-page{
      .book{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.box-text-info {
  position: relative;
  padding: 100px 0;
  &::before {
    background: repeating-linear-gradient(to right, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 32px, transparent 32px, transparent 64px);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 96px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 298px;
    height: 420px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url('/images/material/bg-text-logo.png') no-repeat 0 0;
  }
  .title-info {
    margin: 0;
  }
  .wrapper {
    z-index: 3;
  }
}
.title-info{
  display: flex;
  margin: 40px 0 100px;
  align-items: flex-start;
  flex-flow: row wrap;
  &__img{
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
    img{
      height: 80px;
    }
  }
  &__left{
    flex: 0 0 35%;
    max-width: 35%;
    h3 {
      text-transform: uppercase;
    }
    h5 {
      font-weight: 500;
    }
  }
  &__right{
    flex: 0 0 65%;
    max-width: 65%;
    padding-left: 100px;
    box-sizing: border-box;
    align-self: flex-start;
    display: flex;
    flex-direction: column;

  }

  .info-schedule{
    .inner{
      display: flex;
      padding-top: 20px;
      border-top: 1px solid rgba($color: #000000, $alpha: .1);
    }
    .column{
      p{margin-bottom: 0;}
      figure{
        flex: 1 0 32px;
        max-width: 32px;
      }
    }
    &__text{
      padding-left: 16px;
      small{
        color: $color-primary;
        font-size: 0.75rem;
      }
    }
  }
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}

/* responsive desktop
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1600px) {}
@media all and (max-width: 1441px) {
  .title-info {
    &__left {
      flex: 0 0 30%;
      max-width: 30%;
    }
    &__right {
      flex: 0 0 70%;
      max-width: 70%;
      padding-left: 70px;
    }
  }
}



/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .banner-top {
    figcaption {
      padding-left: 104px;
      h2 {
        font-size: 4rem;
      }
    }
  }
  .content-middle {
    width: calc(100% - 72px);
    .enjoy {
      .bg {
        width: calc(100% + 72px);
      }
    }
  }

  .title-info{
    position: relative;
    padding-bottom: 120px;
    .info-schedule{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &__img {
      margin-bottom: 32px;
      img {
        height: 80px;
      }
    }
    &__left {
      flex: 0 0 40%;
      max-width: 40%;
      h5 {
        margin-bottom: 0;
      }
      button,
      .button-ico-white {
        margin-top: 32px;
      }
    }
    &__right {
      flex: 0 0 60%;
      max-width: 60%;
      padding-left: 64px;
    }
  }

  .box-text-info {
    padding: 80px 0 100px;
    &::before {
      width: 72px;
      background:repeating-linear-gradient(to right, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 24px, transparent 24px, transparent 48px);
    }
    &::after {
      width: 201px;
      height: 376px;
      background-size: auto 100%;
    }
    .wrapper {
      margin: 0 92px 0 104px;
    }
  }

  .enjoy {
    padding: 145px 0;
    .wrap-enjoy {
      &__text {
        h2 {}
      }
    }
  }
  .navbar-page {
    padding: 34px 56px;
    padding-right: 24px;
    &__left {
      h3 {
        font-size: 1.75rem;
      }
    }
    &__right {
      nav {
        display: none;
        ul {
          li {
            a {
              &:last-child {
                margin-right: 32px;
              }
            }
          }
        }
      }
      .book{
        margin-top: 1px;
      }
    }
  }
}



/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .enjoy {
    padding: 109px 0;
    .wrap-enjoy {
      margin-right: 68px;
      &__text {
        display: block;
        h2 {
          font-size: 2.5rem;
        }
        p {
          max-width: unset;
        }
      }
    }
  }
  .box-text-info {
    &::before {
      background: repeating-linear-gradient(to right, var(--primary-yellow, #FFC845), var(--primary-yellow, #FFC845) 20px, transparent 16px,transparent 32px);
      width: 56px;
    }
    &::after {
      display: none;
    }
    .wrapper {
      margin:0 48px 0 88px;
    }
  }
  .title-info{
    padding-bottom: 0;
    &__left,&__right{
      flex: 0 0 100%;
      max-width: 100%;
    }
    &__right{
      padding-left: 0;
      margin-top: 40px;
    }
    .info-schedule{
      position: relative;
    }
  }

  .banner-top {
    figcaption {
      padding-left: 68px;
      h2 {
        max-width: calc(100% - 72px);
        padding-right: 30px;
        margin-bottom: 30px;
      }
    }
  }
  .scroll-down {
    bottom: 104px;
  }
  .navbar-page {
    padding: 32px 24px 32px 56px;
  }

}



/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .banner-top {
    figure {
      &::before {
        transform: rotate(0);
        height: 100svh;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0.64;
      }
    }
    figcaption {
      width: auto;
      right: 0;
      margin-bottom: 74px;
      padding: 0 20px;
      h2 {
        font-size: 2.5rem;
        margin-bottom: 0;
        max-width: 100%;
      }
    }
  }
  .content-middle {
    width: 100%;
    .enjoy {
      .bg {
        width: 100%;
      }
    }
    &.on-stiky{
      // .navbar-page{
      //   .book{
      //     display: none;
      //     opacity: 0;
      //     visibility: hidden;
      //   }
      // }
    }
  }
  .navbar-page {
    padding: 21px 72px 21px 24px;
    flex-direction: row;
    align-items: center;
    nav{
      margin-top: 0;
      margin-right: 0;
      width: 100%;
    }
    &__left{
      h3{
        font-size: 1.438rem;
      }
    }
    &__right{
      width: 100%;
      margin-top: 0;
      justify-content: flex-end;
      nav{
        ul{
          justify-content: center;
          margin: 0 -10px;
          li{
            padding: 0 10px;
            box-sizing: border-box;
            a{
              margin: 0;
              &:last-child{
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .enjoy {
    padding: 105px 0;
    .wrap-enjoy {
      margin-right: 0;
      &__text {
        width: 100%;
      }
    }
  }
  .box-text-info {
    padding: 80px 0;
    &::before {
      display: none;
    }
    .wrapper {
      margin: 0 24px;
    }
  }
  .scroll-down {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    bottom: 8px;
    transform: unset;
    a {
      &::before {
        bottom: 21px;
        left: 29px;
        width: 1px;
        height: 20px;
      }
    }
  }
  .enjoy{
    .wrap-enjoy{
      &__text{
        h2{
          font-size: 2rem;
          max-width: 250px;
        }
      }
    }
  }
  .title-info{
    .info-schedule{
      flex-flow: row wrap;
      width: auto;
      margin: 0;
      .column.column-33{
        flex: unset;
        max-width: 100%;
        padding: 0;
        margin-bottom: 16px;
      }
      .inner{
        padding-top: 16px;
      }
    }
  }
}
